<template>
  <div id="data-list-list-view" class="data-list-container">
    <h3>Medarbejdere</h3>

    <vs-table
      @selected="onEmployeeClicked(selected)"
      ref="table"
      class="mt-8"
      pagination
      v-model="selected"
      :max-items="itemsPerPage"
      search
      :data="employees"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <div
          class="flex flex-wrap-reverse items-center data-list-btn-container"
        >
          <!-- ADD NEW -->
          <div
            @click="$router.push({ name: 'register-employee' }).catch(() => {})"
            class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
          >
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary"
              >Opret ny medarbejder</span
            >
          </div>
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown
          vs-trigger-click
          class="cursor-pointer mb-4 mr-4 items-per-page-handler"
        >
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                employees.length - currentPage * itemsPerPage > 0
                  ? currentPage * itemsPerPage
                  : employees.length
              }}
              of {{ queriedItems }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 12">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 40">
              <span>40</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 100">
              <span>100</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="displayName">Navn</vs-th>
        <vs-th sort-key="email">Email</vs-th>
        <vs-th sort-key="profile">Job Titel</vs-th>
        <!-- <vs-th sort-key="department">Ansat Afdeling</vs-th> -->
        <vs-th sort-key="createDate">Oprettet</vs-th>
        <vs-th sort-key="status">Status</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.name }}
              </p>
            </vs-td>
            <vs-td>
              <p>{{ tr.email }}</p>
            </vs-td>
            <vs-td>
              <p>{{ getProfileName(tr.profile) }}</p>
            </vs-td>

            <vs-td>
              <p>{{ tr.createDate | formatDate }}</p>
            </vs-td>

            <vs-td>
              <div class="centerx">
                <vs-tooltip
                  title="Status på afdelinger"
                  color="gradient"
                  :text="getDepartmantStatusText(tr.departmentStatus)"
                >
                  <div class="progress-outer">
                    <vs-progress
                      :percent="tr.status"
                      color="#99c6b8"
                      :height="25"
                      >primary</vs-progress
                    >
                    <div class="progress-inner">{{ tr.status }}%</div>
                  </div>
                </vs-tooltip>
              </div>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      query: "*",
      employees: [],
      profiles: [],
      selected: [],
      itemsPerPage: 20,
      isMounted: false
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },

    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.employees.length;
    }
  },
  methods: {
    onEmployeeClicked(employee) {
      this.$router
        .push({ name: "employee", params: { employeeId: employee.employeeId } })
        .catch(() => {});
    },

    getEmployees: function() {
      this.$vs.loading();

      this.$http
        .get(process.env.VUE_APP_API + "/employees?query=" + this.query)
        .then(resp => {
          resp.data.forEach(employee => {
            var percentage = this.getCompletionStatus(
              employee.departmentStatus
            );
            employee["status"] = percentage;
          });

          this.employees = resp.data;
          console.log(`${resp.data.length} employees fetched`);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
    getProfiles: function() {
      this.$vs.loading();

      this.$http
        .get(process.env.VUE_APP_API + "/profiles?query=" + this.query)
        .then(resp => {
          this.profiles = resp.data;
          console.log(`${resp.data.length} profiles fetched`);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
    getDepartmantStatusText(departmentStatus) {
      var incompleteDepartments = departmentStatus
        .filter(r => r.state != 3)
        .flatMap(r => r.name);

      return incompleteDepartments.length === 0
        ? "Alle afdelinger har afsluttet behandling af medarbejderen"
        : incompleteDepartments.join(", ") + " mangler at behandle oprettelsen";
    },
    getCompletionStatus(departmentStatus) {
      return (
        (departmentStatus.filter(r => r.state == 3).length /
          departmentStatus.length) *
        100
      );
    },
    getProfileName(id) {
      try {
        return this.profiles.find(x => x.profileId === id).type;
      } catch (error) {
        return "";
      }
    }
  },
  created() {
    this.getProfiles();
    this.getEmployees();
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}

.draft {
  padding: 0 !important;
  text-align: center;
}

.progress-inner {
  /* ... */
  position: absolute;
  top: 0%;
  left: 45%;
  color: black;
  z-index: 9999;
  font-size: medium;
}

.progress-outer {
  position: relative;
}

// .draft-text {
// display: inline-block;
// -webkit-transform: rotate(270deg);
// -moz-transform: rotate(270deg);
// -o-transform: rotate(270deg);
// transform: rotate(270deg);
// font-size: small !important;
// }
</style>
